<template>
  <div class="container-fluid mt-5 pt-5 profile-wrapper">
    <div class="row mt-2 p-5">
      <div class="col-lg-6 col">
        <h1 class="text-white">Account</h1>
        <h3 class="text-white mt-4">Profile</h3>
        <p class="text-white ">
          This information will be displayed publicly so be careful what you
          share.
        </p>
        <div class="row">
          <div class="col-12 col-md-6">
            <input
              v-model="form.name"
              type="text"
              placeholder="Name*"
              class="p-1 text-white w-100"
            />
          </div>
          <div class="col-12 col-md-6 mt-md-0 mt-3">
            <input
              type="text"
              placeholder="Surname*"
              class="p-1 text-white w-100"
              v-model="form.surname"
            />
          </div>
        </div>
        <h3 class="text-white mt-4">Photo</h3>
        <div class="row align-items-center mt-4 change-photo">
          <div class="col-sm-4 col-12">
            <div class="avatar">
              <img
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQyT8tA9swj_AdzROoQIfURlAyPIOc3_n5EBQ&usqp=CAU"
                alt=""
              />
            </div>
          </div>
          <div class="col-sm-3 col-12 mt-2 mt-sm-0">
            <button>Change</button>
          </div>
          <div class="col-sm-3 col-12 mt-2 mt-sm-0">
            <button>Remove</button>
          </div>
        </div>
        <h3 class="text-white mt-5">Personal Information</h3>
        <p class="text-white ">
          This information will be displayed publicly so be careful what you
          share.
        </p>
        <div class="row">
          <div class="col-12 col-sm-6 mb-sm-0 mb-2">
            <input
              type="text"
              placeholder="Email*"
              v-model="form.email"
              class="p-1 text-white w-100"
            />
          </div>
          <div class="col">
            <input
              type="text"
              placeholder="Phone number*"
              v-model="form.phone"
              class="p-1 text-white w-100"
            />
          </div>
        </div>
        <div class="row mt-3 align-items-baseline">
          <div class="col-sm-4 col-12 pp-0 social">
            <b-dropdown
              id="dropdown-1"
              text="social network"
              class="w-100 text-start"
            >
              <b-dropdown-item>First Action</b-dropdown-item>
              <b-dropdown-item>Second Action</b-dropdown-item>
              <b-dropdown-item>Third Action</b-dropdown-item>
              <b-dropdown-divider></b-dropdown-divider>
              <b-dropdown-item active>Active action</b-dropdown-item>
              <b-dropdown-item disabled>Disabled action</b-dropdown-item>
            </b-dropdown>
          </div>
          <div class="col-sm-8 col-12 mt-2">
            <input type="text" placeholder="URL" class="p-1 text-white w-100" />
          </div>
        </div>
        <h4 class="mt-4 ps-4">Add one more social network</h4>
        <div class="row btn-bottom mt-5">
          <div class="col-sm-4 col-12">
            <button @click="updateUserInfo">Save</button>
          </div>
          <div class="col-sm-4 col-12 ms-sm-2 mt-sm-0 mt-2">
            <button>Cancel</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import http from '../../../../../http'

export default {
  data: () => ({
    form: {
      name: '',
      surname: '',
      phone: '',
      countryCode: '',
      birthday: '',
      email: '',
    },
  }),
  computed: {
    ...mapState('User', ['me']),
  },
  methods: {
    updateUserInfo() {
      http.api.put('/users/me/info', this.form).then(() => null)
    },
  },
  created() {
    const {
      baseInfo: { name, surname, phone, countryCode, birthday },
      email,
    } = this.me
    this.form = {
      name,
      surname,
      phone,
      email,
      countryCode,
      birthday,
    }
  },
}
</script>

<style lang="scss" src="./style.scss"></style>
